import * as React from "react"

import ToolScreen from '../../../../components/toolScreen'


const Tool = ({ data }) => (
  <ToolScreen
    name="Principle"
    img_name="principle_logo.png"
    link="https://principleformac.com/"
    description="Principle is the best design tool for professional UI designers crafting advanced animations within high-fidelity prototypes."
    twitterHandleTool="@PrincipleApp"
  />
)

export default Tool;


